import { Routes } from '@angular/router';
import { authGuard } from './auth.guard';
import { RoleKey } from '../../../frio-lib/src/lib/auth-module/models/role-key';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { TempComponent } from './temp/temp.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [ authGuard ],
    data: {},
    resolve: {}
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {},
    resolve: {}
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'sklepy',
    canActivate: [ authGuard ],
    data: {
      roles: [RoleKey.operator]
    },
    loadChildren: () => import('./modules/shops/shops.module').then(m => m.ShopsModule)
  },
  {
    path: 'uzytkownicy',
    canActivate: [ authGuard ],
    data: {
      roles: [RoleKey.operator]
    },
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'konto',
    canActivate: [ authGuard ],
    data: {
      roles: [RoleKey.operator]
    },
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'zadania',
    canActivate: [ authGuard ],
    data: {
      roles: [RoleKey.operator]
    },
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'temp',
    component: TempComponent
  }
];
