<div class="w-full h-full bg-white p-4">
    <div class="w-full h-full border rounded-xl border-gray-300 flex justify-center">
        <section class="w-full h-full max-w-3xl pt-8">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Company</mat-label>
                <input matInput value="Google">
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Opis zlecenia</mat-label>
                <textarea
                matInput
                formControlName="description"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="5"></textarea>
                <mat-error></mat-error>
            </mat-form-field>
            <mat-form-field class="example-form-field">
                <mat-label>Video keywords</mat-label>
                <mat-chip-grid #templateChipGrid aria-label="Enter template form keywords" [(ngModel)]="templateKeywords">
                @for (keyword of templateKeywords(); track keyword) {
                  <mat-chip-row (removed)="removeTemplateKeyword(keyword)">
                    {{keyword}}
                  <button matChipRemove aria-label="'remove template form' + keyword">
                    <mat-icon>cancel</mat-icon>
                  </button>
                  </mat-chip-row>
                }
                </mat-chip-grid>
                <input
                  placeholder="New keyword..."
                  [matChipInputFor]="templateChipGrid"
                  (matChipInputTokenEnd)="addTemplateKeyword($event)"
                />
              </mat-form-field>
        </section>
    </div>
</div>