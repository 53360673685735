<div class="w-full h-full flex">
    <div
        [@openCloseMenu]="!menuCollapsed ? 'open' : 'closed'"
        class="flex flex-col items-center justify-between p-2 overflow-y-auto w-48 border-gray-400 bg-gray-100"
        style="backgrxound-color: #2e2e2e; scrollbar-width: thin; background-color: #d7e3ff;"
    >
        <mat-nav-list
            [@openCloseNav]="!menuCollapsed ? 'open' : 'closed'"
        >
            <div class="w-full flex justify-center mt-2 mb-2">
                <div
                    class="flex rounded-full p-2 xbg-red-900 border"
                    style="
                        border-color: rgba(255, 255, 255, 0.05);
                        background-color: white;
                        /*
                        -webkit-box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
                        -moz-box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
                        box-shadow: 0px 0px 24px 0px rgba(181, 20, 20, 1);
                        */
                        -webkit-box-shadow: 0px 0px 24px 0px rgb(255, 255, 255);
                        -moz-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
                        box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 1);
                    "
                >
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="0.1" stroke="currentColor" class="size-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="size-14">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                </svg>

                </div>
            </div>
            <div class="w-full text-xs flex justify-center mb-6 text-center text-gray-500">
                <p class="w-16">{{appName}}</p>
            </div>
            <mat-list-item class="my-2" [routerLink]="['/']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Pulpit"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        dashboard
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Pulpit</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" [routerLink]="['/sklepy']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Sklepy"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        storefront
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Sklepy</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" [routerLink]="['/sklepy', 'stan']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Ostrzeżenia"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        error
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Stan</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" [routerLink]="['/ustawienia']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Ustawienia"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        tune
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Ustawienia</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" [routerLink]="['/zadania', 'definicje_zadan']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Definicje zadań"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        splitscreen
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Definicje zadań</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" [routerLink]="['/zadania', 'zlecenia_zadan']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Definicje zadań"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        widgets
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Zlecenia zadań</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" [routerLink]="['/zadania', 'zadania']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Definicje zadań"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        view_timeline
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Zadania</div>
                </div>
            </mat-list-item>
            <div class="my-2">
                <mat-divider></mat-divider>
            </div>
            <mat-list-item class="my-2" [routerLink]="['/uzytkownicy']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Użytkownicy"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        group
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Użytkownicy</div>
                </div>
            </mat-list-item>
            <!-- <mat-list-item class="my-2" [routerLink]="['/uzytkownicy/roles']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Użytkownicy"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        group
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Role</div>
                </div>
            </mat-list-item> -->
            <!-- <mat-list-item class="my-2" [routerLink]="['/temp']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Użytkownicy"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        group
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Temp</div>
                </div>
            </mat-list-item> -->
            <div class="my-2">
                <mat-divider></mat-divider>
            </div>
            <mat-list-item class="my-2" [routerLink]="['/konto/zmiana_hasla']" routerLinkActive="router-link-active">
                <div class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Zmiana hasła"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        password
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Zmiana hasła</div>
                </div>
            </mat-list-item>
            <mat-list-item class="my-2" class="flex items-center">
                <button (click)="authService.logout().subscribe()" type="button" class="flex">
                    <mat-icon
                        class="material-icons-outlined fam-icon"
                        matTooltip="Wyloguj się"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!menuCollapsed"
                    >
                        logout
                    </mat-icon>
                    <div [@openCloseMenuItem]="!menuCollapsed ? 'open' : 'closed'">Wyloguj się</div>
                </button>
            </mat-list-item>
        </mat-nav-list>
        <div class="w-full">
            <p class="text-xs w-full text-center text-gray-600">{{environmentName}}, v: {{version}}</p>
            <!-- <div class="my-2">
                <mat-divider></mat-divider>
            </div> -->
            <div class="flex justify-end">
                <button (click)="toggleMenu()" mat-icon-button aria-label="Zwiń/rozwiń menu"
                [matTooltip]="menuCollapsed ? 'Rozwiń menu' : 'Zwiń menu'"
                matTooltipPosition="right"
                >
                <mat-icon
                    class="fam-switch fam-icon"
                    [ngClass]="{'fam-rotated': menuCollapsed}"
                >
                    chevron_left
                </mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="w-full h-full overflow-hidden">
        <router-outlet />
    </div>
</div>

