import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AUTH_CONFIG, AuthConfig, AuthModule } from '../../../frio-lib/src/public-api';
import { ENVIRONMENT_NAME } from '../../../frio-lib/src/lib/common/tokens/environment-name.token';
import { APP_NAME } from '../../../frio-lib/src/lib/common/tokens/app-name.token';
import { environment } from '../environments/environment';
import { AuthService } from '../../../frio-lib/src/lib/auth-module/services/auth.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { LocalStorageWithContextService } from '../../../frio-lib/src/lib/common/services/local.storage.with.context.service';
import { HttpErrorInterceptor } from '@common/interceptors/http-error.interceptor';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { PL_DATE_FORMATS } from '@common/l10n/pl-date-formats';


const AUTH_CONFIG_VALUE: AuthConfig = {
  authApiURL: `${environment.apiURL}/${environment.apiAuthpath}`,
  authTokenKey: `auth_token`,
  authUserKey: "auth_user",
  defaultRedirect: "/",
  autoLogin: true,
  lsUserKey: "fa-serwis-auto-login-username",
  usersCredentials: [],
}

export function jwtOptionsFactory(localStorage: LocalStorageWithContextService) {

  const authTokenKey = "auth_token";

  return {
    tokenGetter: () => {
      return localStorage.get(authTokenKey, false);
    },
    allowedDomains: [
      '127.0.0.1:8000',
      'monitoring-server.test',
      'api.frioarte-monitoring.pl',
      'dev.api.frioarte-monitoring.pl',
      'test.api.frioarte-monitoring.pl'
    ]
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    AuthService,
    importProvidersFrom(
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
          deps: [LocalStorageWithContextService]
        }
      })
    ),
    {
      provide: LOCALE_ID,
      useValue: 'pl-PL'
    },
    {
      provide: AUTH_CONFIG,
      useValue: AUTH_CONFIG_VALUE,
    },
    {
      provide: APP_NAME,
      useValue: environment.appName,
    },
    {
      provide: ENVIRONMENT_NAME,
      useValue: environment.environmentName,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pl-PL'
    },
    {
      provide: MAT_DATE_FORMATS, useValue: PL_DATE_FORMATS
    },
    {
      provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]
    }
  ]
};
