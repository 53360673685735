import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, model, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-temp',
    imports: [
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatChipsModule,
        MatFormFieldModule, MatChipsModule, MatIconModule, MatAutocompleteModule, FormsModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './temp.component.html',
    styleUrl: './temp.component.scss'
})
export class TempComponent {
    readonly templateKeywords = signal(['angular', 'how-to', 'tutorial', 'accessibility']);

    announcer = inject(LiveAnnouncer);

    removeTemplateKeyword(keyword: string) {
        this.templateKeywords.update(keywords => {
        const index = keywords.indexOf(keyword);
        if (index < 0) {
            return keywords;
        }

        keywords.splice(index, 1);
        this.announcer.announce(`removed ${keyword} from template form`);
        return [...keywords];
        });
    }

    addTemplateKeyword(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add our keyword
        if (value) {
        this.templateKeywords.update(keywords => [...keywords, value]);
        this.announcer.announce(`added ${value} to template form`);
        }

        // Clear the input value
        event.chipInput!.clear();
    }
}
