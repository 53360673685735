import { Component, Signal, computed, inject } from '@angular/core';
import { AuthService } from '../../../../../frio-lib/src/lib/auth-module/services/auth.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'fa-filters',
    imports: [
        CommonModule,
        MatIconModule,
        MatListModule,
        // RouterLinkActive,
        // RouterLink,
    ],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
    public authService: AuthService = inject(AuthService);

    items = [
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 nabia\u0142 - 53",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":-27,
            "Warning_treshold":-26,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 nabia\u0142 - 53",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":156.0,
            "Mean":20.3717948718,
            "Alarm_treshold":-18,
            "Warning_treshold":-16,
            "Comparison":"absolute",
            "Delta":-41.76,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 nabia\u0142 - 53",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":336.0,
            "Mean":19.6726190476,
            "Alarm_treshold":-12,
            "Warning_treshold":-7,
            "Comparison":"absolute",
            "Delta":-39.69,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 nabia\u0142 - 53",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":15.9439528024,
            "Alarm_treshold":-8,
            "Warning_treshold":-4,
            "Comparison":"absolute",
            "Delta":-25.59,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 nabia\u0142 - 53",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":11.8643067847,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":95,
            "Warning_treshold":82,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":156.0,
            "Mean":9.6602564103,
            "Alarm_treshold":70,
            "Warning_treshold":64,
            "Comparison":"absolute",
            "Delta":70.67,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":336.0,
            "Mean":10.2827380952,
            "Alarm_treshold":41,
            "Warning_treshold":35,
            "Comparison":"absolute",
            "Delta":60.33,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":13.8112094395,
            "Alarm_treshold":25,
            "Warning_treshold":21,
            "Comparison":"absolute",
            "Delta":19.37,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":16.4867256637,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":-27,
            "Warning_treshold":-26,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":156.0,
            "Mean":18.8717948718,
            "Alarm_treshold":-18,
            "Warning_treshold":-16,
            "Comparison":"absolute",
            "Delta":-14.31,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":336.0,
            "Mean":18.7708333333,
            "Alarm_treshold":-12,
            "Warning_treshold":-7,
            "Comparison":"absolute",
            "Delta":-13.85,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":17.1533923304,
            "Alarm_treshold":-8,
            "Warning_treshold":-4,
            "Comparison":"absolute",
            "Delta":-5.73,
            "Alarm":0,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 10",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":16.1710914454,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":95,
            "Warning_treshold":82,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":156.0,
            "Mean":10.1474358974,
            "Alarm_treshold":70,
            "Warning_treshold":64,
            "Comparison":"absolute",
            "Delta":57.04,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":336.0,
            "Mean":11.0416666667,
            "Alarm_treshold":41,
            "Warning_treshold":35,
            "Comparison":"absolute",
            "Delta":44.32,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":14.5368731563,
            "Alarm_treshold":25,
            "Warning_treshold":21,
            "Comparison":"absolute",
            "Delta":9.62,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":15.9351032448,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":-27,
            "Warning_treshold":-26,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":156.0,
            "Mean":18.0705128205,
            "Alarm_treshold":-18,
            "Warning_treshold":-16,
            "Comparison":"absolute",
            "Delta":-19.2,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":336.0,
            "Mean":17.6607142857,
            "Alarm_treshold":-12,
            "Warning_treshold":-7,
            "Comparison":"absolute",
            "Delta":-17.32,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":16.0501474926,
            "Alarm_treshold":-8,
            "Warning_treshold":-4,
            "Comparison":"absolute",
            "Delta":-9.02,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 11",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":14.6017699115,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":95,
            "Warning_treshold":82,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":156.0,
            "Mean":7.5448717949,
            "Alarm_treshold":70,
            "Warning_treshold":64,
            "Comparison":"absolute",
            "Delta":67.92,
            "Alarm":0,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":336.0,
            "Mean":8.4285714286,
            "Alarm_treshold":41,
            "Warning_treshold":35,
            "Comparison":"absolute",
            "Delta":50.32,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":10.8171091445,
            "Alarm_treshold":25,
            "Warning_treshold":21,
            "Comparison":"absolute",
            "Delta":17.13,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":12.6696165192,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":-27,
            "Warning_treshold":-26,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":156.0,
            "Mean":19.1923076923,
            "Alarm_treshold":-18,
            "Warning_treshold":-16,
            "Comparison":"absolute",
            "Delta":-18.62,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":336.0,
            "Mean":18.6130952381,
            "Alarm_treshold":-12,
            "Warning_treshold":-7,
            "Comparison":"absolute",
            "Delta":-16.08,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":17.1533923304,
            "Alarm_treshold":-8,
            "Warning_treshold":-4,
            "Comparison":"absolute",
            "Delta":-8.94,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 12",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":15.6194690265,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":95,
            "Warning_treshold":82,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":156.0,
            "Mean":10.2371794872,
            "Alarm_treshold":70,
            "Warning_treshold":64,
            "Comparison":"absolute",
            "Delta":53.47,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":336.0,
            "Mean":11.8482142857,
            "Alarm_treshold":41,
            "Warning_treshold":35,
            "Comparison":"absolute",
            "Delta":32.6,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":11.9115044248,
            "Alarm_treshold":25,
            "Warning_treshold":21,
            "Comparison":"absolute",
            "Delta":31.9,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":15.7109144543,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":-27,
            "Warning_treshold":-26,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":156.0,
            "Mean":17.9166666667,
            "Alarm_treshold":-18,
            "Warning_treshold":-16,
            "Comparison":"absolute",
            "Delta":-16.67,
            "Alarm":0,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":336.0,
            "Mean":17.7321428571,
            "Alarm_treshold":-12,
            "Warning_treshold":-7,
            "Comparison":"absolute",
            "Delta":-15.81,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":17.1268436578,
            "Alarm_treshold":-8,
            "Warning_treshold":-4,
            "Comparison":"absolute",
            "Delta":-12.83,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 13",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":14.9292035398,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":95,
            "Warning_treshold":82,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":156.0,
            "Mean":17.5256410256,
            "Alarm_treshold":70,
            "Warning_treshold":64,
            "Comparison":"absolute",
            "Delta":35.02,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":336.0,
            "Mean":18.1517857143,
            "Alarm_treshold":41,
            "Warning_treshold":35,
            "Comparison":"absolute",
            "Delta":30.37,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":19.9321533923,
            "Alarm_treshold":25,
            "Warning_treshold":21,
            "Comparison":"absolute",
            "Delta":18.72,
            "Alarm":0,
            "Warning":0,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"Po2",
            "Samples":339.0,
            "Mean":23.6637168142,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":-4,
            "Week":1739404800000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":null,
            "Mean":null,
            "Alarm_treshold":-27,
            "Warning_treshold":-26,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":1,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-3,
            "Week":1740009600000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":156.0,
            "Mean":17.8653846154,
            "Alarm_treshold":-18,
            "Warning_treshold":-16,
            "Comparison":"absolute",
            "Delta":-17.28,
            "Alarm":0,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":1,
            "To_many_samples":0
        },
        {
            "Week_number":-2,
            "Week":1740614400000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":336.0,
            "Mean":17.9761904762,
            "Alarm_treshold":-12,
            "Warning_treshold":-7,
            "Comparison":"absolute",
            "Delta":-17.79,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":0
        },
        {
            "Week_number":-1,
            "Week":1741219200000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":16.8613569322,
            "Alarm_treshold":-8,
            "Warning_treshold":-4,
            "Comparison":"absolute",
            "Delta":-12.35,
            "Alarm":1,
            "Warning":1,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        },
        {
            "Week_number":0,
            "Week":1741824000000,
            "Device_name":"Rega\u0142 O\/W - 14",
            "Device_type":"regal",
            "Parameter_key":"s_SHSetpointWork",
            "Samples":339.0,
            "Mean":14.7787610619,
            "Alarm_treshold":null,
            "Warning_treshold":null,
            "Comparison":"absolute",
            "Delta":null,
            "Alarm":null,
            "Warning":null,
            "No_samples":0,
            "Not_enough_samples":0,
            "To_many_samples":1
        }
    ]

    public getDate(value: number) {
        return new Date(value).toLocaleDateString("pl-PL")
    }

    public isSet(value: number | null) {
        return value && value > 0;
    }
}
