<div class="flex flex-col w-full h-full justify-center items-center">
  <h1 class="text-4xl">Witaj, {{authService.user()?.name}}</h1>
  <p class="mt-8 w-96 text-xs text-center text-gray-500">{{authService.user() | json}}</p>
  <div class="w-full">
  </div>
    <table class="border-collapse border border-gray-400">
        <tr>
            <th>Name</th>
        </tr>
        <tr *ngFor="let item of items ">
            <td>
                {{item.Week_number}}
            </td>
            <td>
                {{getDate(item.Week)}}
            </td>
            <td>
                {{item.Device_name}}
            </td>
            <td>
                {{item.Parameter_key}}
            </td>
            <td>
                {{item.Samples}}
            </td>
            <td>
                {{item.Mean}}
            </td>
            <td>
                {{item.Mean}}
            </td>
            <td [ngClass]="{'bg-red-200' : isSet(item.Alarm)}">
                {{item.Alarm}}
            </td>
            <td [ngClass]="{'bg-red-100' : isSet(item.Warning)}">
                {{item.Warning}}
            </td>
        </tr>  
    </table>
</div>

